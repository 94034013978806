import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { plainToInstance } from "class-transformer";

import { Firebase } from "./firebase";
import { Server } from "../models";
import { User } from "models/user";

declare module "axios" {
    interface AxiosResponse<T = any> extends Promise<T> {
        data: T;
    }
}

export class HttpClient {
    protected readonly instance: AxiosInstance;
    private readonly subObject?: string;

    protected constructor(baseURL: string, subObject?: string) {
        this.instance = axios.create({ baseURL, timeout: 30000 });
        this.subObject = subObject;

        this.initializeResponseInterceptor();
    }

    public static getHeaders = async () => {
        const Authorization = `Bearer ${(await Firebase.Instance.currentUser?.getIdToken()) ?? ""}`;

        return {
            Authorization,
        };
    };

    /* Utility method to get the response data */
    private handleResponse = ({ data }: AxiosResponse) => data;

    /* Utility method to catch the error and throw a formatted one instead */
    private handleError = (error: any): Promise<any> => {
        if (error.response?.data?.message) {
            return Promise.reject(error.response.data.message);
        }

        return Promise.reject(error);
    };

    /* Attach the interceptor to the instance */
    private initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            (res) => this.handleResponse(res),
            this.handleError,
        );
    };

    /* Request utility that automatically handles the response class and metadata */
    protected request = async <T>(
        config: AxiosRequestConfig,
        Model: any,
    ): Promise<T> => {
        const response = await this.instance.request(config);

        return plainToInstance(
            Model,
            this.subObject ? response[this.subObject] : response,
        ) as unknown as T;
    };
}

export class Services extends HttpClient {
    private static _instance?: Services;

    constructor() {
        super("https://api.mafiahub.dev", "result");
    }

    static get Instance(): Services {
        return this._instance || (this._instance = new this());
    }

    fetchServers = async () => {
        return this.request<Server[]>(
            {
                method: "GET",
                url: "/rcon/servers",
            },
            Server,
        );
    };

    getMe = async () => {
        return this.request<User>(
            {
                method: "GET",
                url: "/users/me",
                headers: await HttpClient.getHeaders(),
            },
            User,
        );
    };
}
