import ResizeObserver from "resize-observer-polyfill";
import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { App } from "./App";

// Ultralight shit fix
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
