export class User {
    discord_id: string = "";
    discord_username: string = "";

    display_name: string = "";

    email: string = "";
    id: string = "";

    is_banned: boolean = false;
    is_setup_completed: boolean = false;

    name_to_display: string = "";

    security_level: number = 0;
}
